// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./Pretendard-Black.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./Pretendard-Bold.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./Pretendard-ExtraBold.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./Pretendard-ExtraLight.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("./Pretendard-Light.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("./Pretendard-Medium.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("./Pretendard-Regular.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_7___ = new URL("./Pretendard-SemiBold.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_8___ = new URL("./Pretendard-Thin.woff", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: 'Pretendard Black';
  font-style: normal;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('woff');
}
@font-face {
  font-family: 'Pretendard Bold';
  font-style: normal;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('woff');
}
@font-face {
  font-family: 'Pretendard ExtraBold';
  font-style: normal;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('woff');
}
@font-face {
  font-family: 'Pretendard ExtraLight';
  font-style: normal;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('woff');
}
@font-face {
  font-family: 'Pretendard Light';
  font-style: normal;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format('woff');
}
@font-face {
  font-family: 'Pretendard Medium';
  font-style: normal;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format('woff');
}
@font-face {
  font-family: 'Pretendard';
  font-style: normal;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_6___}) format('woff');
}
@font-face {
  font-family: 'Pretendard SemiBold';
  font-style: normal;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_7___}) format('woff');
}
@font-face {
  font-family: 'Pretendard Thin';
  font-style: normal;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_8___}) format('woff');
}
`, "",{"version":3,"sources":["webpack://./src/fonts/font.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;EAC/B,kBAAkB;EAClB,2DAAkD;AACpD;AACA;EACE,8BAA8B;EAC9B,kBAAkB;EAClB,2DAAiD;AACnD;AACA;EACE,mCAAmC;EACnC,kBAAkB;EAClB,2DAAsD;AACxD;AACA;EACE,oCAAoC;EACpC,kBAAkB;EAClB,2DAAuD;AACzD;AACA;EACE,+BAA+B;EAC/B,kBAAkB;EAClB,2DAAkD;AACpD;AACA;EACE,gCAAgC;EAChC,kBAAkB;EAClB,2DAAmD;AACrD;AACA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,2DAAoD;AACtD;AACA;EACE,kCAAkC;EAClC,kBAAkB;EAClB,2DAAqD;AACvD;AACA;EACE,8BAA8B;EAC9B,kBAAkB;EAClB,2DAAiD;AACnD","sourcesContent":["@font-face {\n  font-family: 'Pretendard Black';\n  font-style: normal;\n  src: url('./Pretendard-Black.woff') format('woff');\n}\n@font-face {\n  font-family: 'Pretendard Bold';\n  font-style: normal;\n  src: url('./Pretendard-Bold.woff') format('woff');\n}\n@font-face {\n  font-family: 'Pretendard ExtraBold';\n  font-style: normal;\n  src: url('./Pretendard-ExtraBold.woff') format('woff');\n}\n@font-face {\n  font-family: 'Pretendard ExtraLight';\n  font-style: normal;\n  src: url('./Pretendard-ExtraLight.woff') format('woff');\n}\n@font-face {\n  font-family: 'Pretendard Light';\n  font-style: normal;\n  src: url('./Pretendard-Light.woff') format('woff');\n}\n@font-face {\n  font-family: 'Pretendard Medium';\n  font-style: normal;\n  src: url('./Pretendard-Medium.woff') format('woff');\n}\n@font-face {\n  font-family: 'Pretendard';\n  font-style: normal;\n  src: url('./Pretendard-Regular.woff') format('woff');\n}\n@font-face {\n  font-family: 'Pretendard SemiBold';\n  font-style: normal;\n  src: url('./Pretendard-SemiBold.woff') format('woff');\n}\n@font-face {\n  font-family: 'Pretendard Thin';\n  font-style: normal;\n  src: url('./Pretendard-Thin.woff') format('woff');\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
